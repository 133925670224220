import tippy, { Instance, Placement, Props } from 'tippy.js';
import 'tippy.js/dist/tippy.css'
import { InjectionKey, inject as VueInject, reactive } from 'vue';

export interface TippyStateInterface {
  readonly tippies: { [selector: string]: Instance<Props>[]};
}

class TippyState implements TippyStateInterface{
  tippies: { [selector: string]: Instance<Props>[]} = {};
}

export interface TippyStoreInterface {
  readonly state: TippyStateInterface;
  getTippy(selector: string): Instance<Props>[];
  setTippy(selector: string, content: string | Element, placement?: Placement): Instance<Props>[];
  setTippyV2(selector: string, optionalProps?: Partial<Props>): Instance<Props>[];
  seti18nTippy(t: any, selector: string, tkey: string, placement?: Placement): Instance<Props>[];
  destroy(selector: string): void;
}

class TippyStore implements TippyStoreInterface {
  private _state: TippyState;
  get state(): TippyStateInterface {
    return this._state;
  }
  constructor(
  ){
    this._state = reactive(new TippyState());
  }
  getTippy(selector: string): Instance<Props>[] {
    return this._state.tippies[selector];
  }
  setTippy(selector: string, content: string | Element, placement?: Placement): Instance<Props>[]{
    return this.setTippyV2(selector, {content, placement})
  }
  setTippyV2(selector: string, optionalProps?: Partial<Props>): Instance<Props>[]{
    this.destroy(selector);
    if(optionalProps){
      optionalProps.allowHTML = optionalProps?.content !== 'string';
      optionalProps.theme = 'translucent';
    }
    const tippies = tippy(selector, optionalProps);
    this._state.tippies[selector] = tippies;
    return tippies;
  }
  seti18nTippy(t: any, selector: string, tkey: string, placement?: Placement): Instance<Props>[] {
    this.destroy(selector);
    const tippies = tippy(selector, {
      placement, 
      theme: 'translucent',
      onShow(instance) {
        instance.setContent(t(tkey));
      },
    });
    this._state.tippies[selector] = tippies;
    return tippies;
  }
  destroy(selector: string): void {
    const keys = Object.keys(this._state.tippies);
    if(keys.includes(selector)){
      this._state.tippies[selector].forEach(tip => !tip.state.isDestroyed && tip.destroy());
      this._state.tippies[selector].splice(0);
    }
  }
}

export class TippyStoreFactory {
  create(): TippyStoreInterface {
    return new TippyStore();
  }
}

export const tippyKey: InjectionKey<TippyStoreInterface> = Symbol("TippyStoreInterface");
export const useTippy = () => VueInject(tippyKey) as TippyStoreInterface;