import LoadGlossariesInterface from './LoadGlossariesInterface'
import { injectable } from 'tsyringe';
import { API } from 'aws-amplify';
import * as Sentry from '@sentry/browser'
import GlossaryInterface from '@/entities/GlossaryInterface';
import Glossary from '@/entities/Glossary';
import AbstractUsecase from '@/usecases/AbstractUsecase';
import APIError from '@/entities/APIError';
import { HTTPRequestAsync } from '@/infra/HTTPRequest';

@injectable()
export default class extends AbstractUsecase implements LoadGlossariesInterface {  
  
  async handleAsync(): Promise<GlossaryInterface[]> {
    const path = `/v1/glossaries?`;    
    try {
      // #4434
      const result = await HTTPRequestAsync('get', {
        apiName: 'MTrans.Glossary.Query.API',
        altAPIName: 'MTrans.Glossary.Query.API.ALT',
        path,
        init: { 
          headers: { 
            "Authorization": await super.GetAuthorizationAsync()
          }
        }
      });
      // const result = await API.get('MTrans.Glossary.Query.API', path, {
      //   headers: {
      //     "Authorization": await super.GetAuthorizationAsync()
      //   }
      // });
      const glossaries = (result as unknown as GlossaryInterface[]).map(r => new Glossary(r));      
      return glossaries;
    } catch (error) {
      Sentry.captureException(error);
      throw new APIError(error);
    }
  }
}