
import { defineComponent } from 'vue';
import { useRouter } from 'vue-router';
import { RouterGuard } from './router';

export default defineComponent({
  name: 'App',
  setup(){

    const router = useRouter();
    const routerGuard = RouterGuard.getInstance();    
    const { beforeEach, afterEach } = routerGuard.get();
    router.beforeEach(beforeEach);
    router.afterEach(afterEach);

  }
});
