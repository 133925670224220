interface ResponseDataInterface {
  "error-code": string;
  "code": string;
  "error-message": string;
  "message": string;
}

interface ResponseInterface {
  data: ResponseDataInterface;
  status: number;
}

export default class APIError extends Error {
  private _response!: ResponseInterface;
  private _code!: string;
  constructor(e: any) {       
    super(`${e.message}`);
    if(e.response){
      this._response = e.response;
      if(this._response.data) {
        if ("error-code" in this._response.data) {
          this.message = `${this._response.data["error-message"]} (${this._response.data["error-code"]})`;
        } else {
          this._code = `${this._response.data["code"]}`;
          this.message = `${this._response.data["message"]}`;
        }
      }
    }   
  }
  get response(): ResponseInterface {
    return this._response;
  }
  get code() {
    return this._code;
  }
}