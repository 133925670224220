import en from "@/translation/en.json";
import ja from"@/translation/ja.json";
import { Locales } from "@/resources/locales";

export default function getMessages() {
    
    const convertJson = (msg: any) => {
        const text = JSON.stringify(msg)
                         .replace("${YYYY}", new Date().getFullYear()
                         .toString())
        return JSON.parse(text);
    };

    return {
        [Locales.EN]: convertJson(en),
        [Locales.JA]: convertJson(ja)
    }
    
}