// Reference:
// Manage Vue i18n with Typescript: https://medium.com/js-dojo/manage-vue-i18n-with-typescript-958b2f69846f

export enum Locales {
  EN = 'en',
  JA = 'ja',
}

export const LOCALES = [
  { value: Locales.EN, caption: 'English' },
  { value: Locales.JA, caption: '日本語' }
]

