import { Auth } from 'aws-amplify';

export default abstract class {  
  private authorization?: string;
  constructor(args?: {authorization?: string}) {
    this.authorization = args?.authorization;
  }
  async GetAuthorizationAsync() {
    const session = await Auth.currentSession();
    this.authorization = session.getIdToken().getJwtToken();
    // 別アカウントで再ログインしてそのまま操作すると前のアカウントのトークンを使ってしまうため
    // if(!this.authorization) {
    //   const session = await Auth.currentSession();
    //   this.authorization = session.getIdToken().getJwtToken();
    // }
    return this.authorization;
  }
}