import ContractInterface from './ContractInterface';
import ContractUserInterface from './ContractUserInterface';
import { GetJapaneseCurrentDate } from './Utility';

export default class implements ContractInterface {
    readonly contractID: string = '';
    readonly customerID: string = '';
    readonly startDate: string = '';
    readonly endDate: string = '';
    readonly mtransApplicationIDList: string[] = [];
    readonly mtransFeatureIDList: string[] = [];
    readonly mtEngineIDList: string[] = [];
    readonly numberOfUsers: number | null = null;
    readonly contractUserList: ContractUserInterface[] = [];
    readonly contractUserListCount: number = 0;
    readonly contractPhase: number = 0;
    readonly contractCancelled: boolean = false;
    readonly creationDate: string = '';
    readonly creationTime: string = '';
    readonly lastUpdateDate: string = '';
    readonly lastUpdateTime: string = '';
    get isInProgress(): boolean {
        const stDate = new Date(this.startDate);
        const edDate = new Date(this.endDate);
        const today = GetJapaneseCurrentDate();
        const result = stDate <= today && today <= edDate && !this.contractCancelled;
        return result;
    }
    get isDone(): boolean {
        const edDate = new Date(this.endDate);
        const today = GetJapaneseCurrentDate();
        const result = edDate < today && !this.contractCancelled;
        return result;
    }
    get isScheduled(): boolean {
        const stDate = new Date(this.startDate);        
        const today = GetJapaneseCurrentDate();
        const result = today < stDate && !this.contractCancelled;
        return result;
    }
    get hasMTransForOffice(): boolean {
        return this.mtransApplicationIDList.includes('1');
    }
    get hasMTransForPhraseTMS(): boolean {
        return this.mtransApplicationIDList.includes('2');
    }
    get hasMTransForTrados(): boolean {
        return this.mtransApplicationIDList.includes('3');
    }
    get hasMTransTMFindAndReplace(): boolean {
        return this.mtransApplicationIDList.includes('4');
    }
    get hasMTransAPI(): boolean {
        return this.mtransApplicationIDList.includes('A');
    }
    get hasCloudTranslation(): boolean {
        return this.mtransFeatureIDList.includes('1');
    }
    get hasTermAdaptation(): boolean {
        return this.mtransFeatureIDList.includes('2');
    }
    get hasTextProcessing(): boolean {
        return this.mtransFeatureIDList.includes('3');
    }
    constructor(props: {
        contractID?: string;
        customerID?: string;
        startDate?: string;
        endDate?: string;
        mtransApplicationIDList?: string[];
        mtransFeatureIDList?: string[];
        mtEngineIDList?: string[];
        numberOfUsers?: number | null;
        contractUserList?: ContractUserInterface[];
        contractUserListCount?: number;
        contractPhase?: number;
        contractCancelled?: boolean;
        creationDate?: string;
        creationTime?: string;
        lastUpdateDate?: string;
        lastUpdateTime?: string;
    }){
        if(props.contractID) this.contractID = props.contractID;
        if(props.customerID) this.customerID = props.customerID;
        if(props.startDate) this.startDate = props.startDate;
        if(props.endDate) this.endDate = props.endDate;
        if(props.mtransApplicationIDList) this.mtransApplicationIDList = props.mtransApplicationIDList;
        if(props.mtransFeatureIDList) this.mtransFeatureIDList = props.mtransFeatureIDList;
        if(props.mtEngineIDList) this.mtEngineIDList = props.mtEngineIDList;
        if(props.numberOfUsers) this.numberOfUsers = props.numberOfUsers;
        if(props.contractUserList) this.contractUserList = props.contractUserList;
        if(props.contractUserListCount) this.contractUserListCount = props.contractUserListCount;
        if(props.contractPhase) this.contractPhase = props.contractPhase;
        if(props.contractCancelled) this.contractCancelled = props.contractCancelled;
        if(props.creationDate) this.creationDate = props.creationDate;
        if(props.creationTime) this.creationTime = props.creationTime;
        if(props.lastUpdateDate) this.lastUpdateDate = props.lastUpdateDate;
        if(props.lastUpdateTime) this.lastUpdateTime = props.lastUpdateTime;
    }
}