import { API } from 'aws-amplify';
import * as Sentry from '@sentry/browser'
import APIError from '@/entities/APIError';

export interface AmplifyAPIArg {
  apiName: string;
  altAPIName: string;
  path: string;
  init: any;
}

export async function HTTPRequestAsync(method: 'get' | 'post' | 'put' | 'patch' | 'del', arg: AmplifyAPIArg) {
  // const asyncAction = {
  //   'get': API.get,
  //   'post': API.post,
  //   'put': API.put,
  //   'patch': API.patch,
  //   'delete': API.del,
  // }[method];

  try {
    return await API[method](arg.apiName, arg.path, arg.init);   
    // An error occurs       
    // return await asyncAction(arg.apiName, arg.path, arg.init);
  } catch (error) {
    if (`${error}`.includes('Network Error') && arg.altAPIName) {
      Sentry.captureMessage(`Network Error: ${arg.apiName}. Sending a 2nd request via ${arg.altAPIName}`);
      return await API[method](arg.altAPIName, arg.path, arg.init);          
      // return await asyncAction(arg.altAPIName, arg.path, arg.init);
    } else {
      throw new APIError(error);
    }
  }
}