
import SaveMyLanguageInterface from './SaveMyLanguageInterface'
import { injectable } from 'tsyringe';
import { Operation } from 'fast-json-patch';
import { Auth, API } from 'aws-amplify';
import * as Sentry from '@sentry/browser'
import { HTTPRequestAsync } from '@/infra/HTTPRequest';
 
@injectable()
export default class implements SaveMyLanguageInterface {
  
  async handleAsync(language: string): Promise<void> {
    try {      
      const operations: Operation[] = [{
        op: "replace",
        path: "/language",
        value: language
      }]
      const session = await Auth.currentSession();
      // #4434      
      await HTTPRequestAsync('patch', {
        apiName: 'MTrans.API.Users',
        altAPIName: 'MTrans.API.Users.ALT',
        path: '/v1/my-account',
        init: { 
          headers: { 
            "Authorization": session.getIdToken().getJwtToken() 
          }, 
          body: operations
        }
      })
      // await API.patch("MTrans.API.Users", '/v1/my-account', {
      //   headers: {
      //     "Authorization": session.getIdToken().getJwtToken()
      //   },
      //   body: operations
      // });
    } catch (error) {
      Sentry.captureException(error);
      throw error;
    }
  }
}