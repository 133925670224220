import { formatToTimeZone } from 'date-fns-timezone';
import { CognitoUser } from 'amazon-cognito-identity-js';
import { camelCase, startCase } from 'lodash';

export function toPascalCase(str: string): string {
  return startCase(camelCase(str)).replace(/ /g, '');
}

export function getRandomNumber(maxNumber: number): number {
  return Math.floor(Math.random() * (maxNumber + 1));
}

export function getRandomClassName(maxNumber: number): string {
  let result = '';
  const characters = 'ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz';
  const charactersLength = characters.length;
  for ( let i = 0; i < maxNumber; i++ ) {
      result += characters.charAt(Math.floor(Math.random() * charactersLength));
  }
  return result;
}

export function randomDate(start: Date, end: Date) {
  return new Date(start.getTime() + Math.random() * (end.getTime() - start.getTime()));
}

export function repeat<T extends () => S, S>(count: number, fn: T): S[] {
  const result: S[] = [];
  [count].map((v) => {
    for(let i = 0; i < v; i++){
      result.push(fn())
    }
  })
  return result;
}

export function sleep(ms: number) {
  return new Promise((resolve) => setTimeout(resolve, ms));
}

export function validateEmailAddressFormat(value: string): boolean {
  return /^[a-zA-Z0-9.!#$%&'*+/=?^_`{|}~-]+@[a-zA-Z0-9-]+(?:\.[a-zA-Z0-9-]+)*$/.test(value);
}

export function createQueryParamsString(params: {[name: string]: string}){
  const queryParamsString = Object.keys(params)
                           .filter(key => params[key] && params[key] !== 'undefined')
                           .map(key => `${key}=${params[key]}`)
                           .join('&');
  return queryParamsString;
}

export function fillMonthAndDayWithZero(date: string): string {
  const [year, month, day] = date.split(/[/-]/);
  return `${year}-${month.length === 1 ? '0' : ''}${month}-${day.length === 1 ? '0' : ''}${day}`;
}

export class DateEx extends Date {
  constructor(date: number | Date | {year: number, monthIndex: number, date: number/*, hours?: number, minutes?: number, seconds?: number, ms?: number*/}) {
    if (typeof date === 'number' || date instanceof Date) {
      super(date)
    } else {
      super(date.year, date.monthIndex, date.date);
      // if (date.hours) {
      //   if (date.minutes) {
      //     if (date.seconds) {
      //       if (date.ms) {
      //         super(date.year, date.monthIndex, date.date, date.hours, date.minutes, date.seconds, date.ms);        
      //       } else {
      //         super(date.year, date.monthIndex, date.date, date.hours, date.minutes, date.seconds);
      //       }
      //     } else {
      //       super(date.year, date.monthIndex, date.date, date.hours, date.minutes);
      //     }
      //   } else {
      //     super(date.year, date.monthIndex, date.date, date.hours);
      //   }
      // } else {
      //   super(date.year, date.monthIndex, date.date);
      // }
      // super(date.year, date.monthIndex, date.date, date.hours, date.minutes, date.seconds, date.ms); => Invalid Datte
    }
  }
  private getThisMonday() {
    const copy = new Date(this);
    const day = copy.getDay();
    const diff = copy.getDate() - day + (day == 0 ? -6:1);
    copy.setDate(diff);
    return new DateEx(copy);
  }
  private getLastMonday() {
    const thisMonday = this.getThisMonday()
    thisMonday.setDate(thisMonday.getDate() - 7);
    return thisMonday;
  }
  private toLocalISOString() {
    // https://stackoverflow.com/questions/10830357/javascript-toisostring-ignores-timezone-offset
    // https://stackoverflow.com/questions/33184096/date-new-date-date-valueof-vs-date-now
    const tzoffset = this.getTimezoneOffset() * 60000; //offset in milliseconds
    return (new Date(this.getTime() - tzoffset)).toISOString().slice(0, -1);
  }
  get "yyyy-mm-dd"() {
    return this.toLocalISOString().split("T")[0];
  }
  get "yyyy-mm"() {
    return [...this['yyyy-mm-dd'].split("-")].splice(0, 2).join("-")
  }
  get previousDay() {
    const copy = new Date(this);
    copy.setDate(this.getDate() - 1)
    return new DateEx(copy);
  }
  get thisWeek() {
    const startDate = this.getThisMonday();
    return {
      startDate,
      endDate: new DateEx(this.getThisMonday().setDate(startDate.getDate() + 6))
    }
  }
  get previousWeek() {
    const startDate = this.getLastMonday();
    return {
      startDate,
      endDate: new DateEx(this.getLastMonday().setDate(startDate.getDate() + 6))
    }
  }
  get thisMonth() {
    const year = this.getFullYear();
    const monthIndex = this.getMonth();
    const startDate = new DateEx({year, monthIndex, date: 1});
    const endDate =  new DateEx({year, monthIndex, date: new DateEx({year, monthIndex: monthIndex + 1, date: 0}).getDate()});
    return {
      startDate,
      endDate
    }
  }
  get previousMonth() {
    const thisMonthStartDate = this.thisMonth.startDate;
    const startDate = new DateEx(thisMonthStartDate.setMonth(thisMonthStartDate.getMonth() - 1));
    const year = startDate.getFullYear();
    const monthIndex = startDate.getMonth();
    const endDate = new DateEx({year, monthIndex, date: new DateEx({year, monthIndex: monthIndex + 1, date: 0}).getDate()});
    return {
      startDate,
      endDate
    }
  }
  get thisYear() {
    const year = this.getFullYear();
    const startDate = new DateEx({year, monthIndex: 0, date: 1});
    const endDate =  new DateEx({year, monthIndex: 11, date: new DateEx({year, monthIndex: 12, date: 0}).getDate()});
    return {
      startDate,
      endDate
    }
  }
  get previousYear() {
    const year = this.getFullYear() -1;
    const startDate = new DateEx({year, monthIndex: 0, date: 1});
    const endDate =  new DateEx({year, monthIndex: 11, date: new DateEx({year, monthIndex: 12, date: 0}).getDate()});
    return {
      startDate,
      endDate
    }
  }
}

export function GetJapaneseCurrentDate(): Date {
  const currentMonthJST = formatToTimeZone(new Date(), 'YYYY-MM-DD', {timeZone: 'Asia/Tokyo'});
  // const today = fillMonthAndDayWithZero(new Date().toLocaleDateString('ja-jp'));
  return new Date(currentMonthJST);
}

export class JapaneseCurrentDate extends DateEx {
  constructor() {
    super(GetJapaneseCurrentDate())
  }
}

export function encodeToUrlSafe(base64Text: string): string {
  const result = base64Text.replaceAll("+", "-").replaceAll("/", "_");
  return result;
}

// get all months between two dates?: https://stackoverflow.com/questions/30464628/javascript-get-all-months-between-two-dates/30465843
export function getMonthsBetween(startDate: string, endDate: string) {
  const start      = startDate.split('-');
  const end        = endDate.split('-');
  const startYear  = parseInt(start[0]);
  const endYear    = parseInt(end[0]);
  const dates      = [];

  for(let i = startYear; i <= endYear; i++) {
    const endMonth = i != endYear ? 11 : parseInt(end[1]) - 1;
    const startMon = i === startYear ? parseInt(start[1])-1 : 0;
    for(let j = startMon; j <= endMonth; j = j > 12 ? j % 12 || 11 : j+1) {
      const month = j+1;
      const displayMonth = month < 10 ? '0'+month : month;
      dates.push([i, displayMonth, '01'].join('-'));
    }
  }
  return dates;
}


// https://docs.aws.amazon.com/ja_jp/encryption-sdk/latest/developer-guide/js-examples.html
// import { KMS } from '@aws-crypto/client-browser';

// export const encryptAsync = async (text: string): Promise<string> => {
  
//   const credentials: KMS.ClientConfiguration = {
//     accessKeyId: process.env.VUE_APP_AWS_IAM_ACCESS_KEY_ID as string,
//     secretAccessKey: process.env.VUE_APP_AWS_IAM_SECRET_ACCESS_KEY as string,    
//     region: process.env.VUE_APP_AWS_KMS_REGION as string
//   };
  
//   const kms = new KMS(credentials);

//   const request: KMS.EncryptRequest = {
//     KeyId: `arn:aws:kms:${process.env.VUE_APP_AWS_KMS_REGION}:${process.env.VUE_APP_AWS_NUMBER}:key/${process.env.VUE_APP_AWS_KMS_KEY_ID}`,
//     EncryptionAlgorithm: "RSAES_OAEP_SHA_256",
//     Plaintext: text,
//   };
  
//   const { CiphertextBlob } = await kms.encrypt(request).promise();
  
//   const base64Text = CiphertextBlob?.toString('base64') as string;

//   return base64Text.replace(/==$/, "");
// };

// Decrypt text with AWS KMS in NodeJs: https://stackoverflow.com/questions/56827993/decrypt-text-with-aws-kms-in-nodejs
// export const decryptAsync = async (text: string): Promise<string> => {
  
//   const credentials: KMS.ClientConfiguration = {
//     accessKeyId: process.env.VUE_APP_AWS_IAM_ACCESS_KEY_ID as string,
//     secretAccessKey: process.env.VUE_APP_AWS_IAM_SECRET_ACCESS_KEY as string,    
//     region: process.env.VUE_APP_AWS_KMS_REGION as string
//   };
  
//   const kms = new KMS(credentials);

//   const request: KMS.DecryptRequest = {
//     KeyId: `arn:aws:kms:${process.env.VUE_APP_AWS_KMS_REGION}:${process.env.VUE_APP_AWS_NUMBER}:key/${process.env.VUE_APP_AWS_KMS_KEY_ID}`,
//     EncryptionAlgorithm: "RSAES_OAEP_SHA_256",
//     CiphertextBlob: Buffer.from(text, 'base64')
//   };

//   const { Plaintext } = await kms.decrypt(request).promise();

//   return Plaintext?.toString() as string;
// }

// https://ourcodeworld.com/articles/read/189/how-to-create-a-file-and-generate-a-download-with-javascript-in-the-browser-without-a-server
export function download(
    fileName: string, 
    mimeType: string, 
    charset: string, 
    content: string | Blob, 
  ) {
  const element = document.createElement('a');
  const hrefValue = typeof content === 'string' ? `data:${mimeType};charset=${charset},${content}` : (window.URL || window.webkitURL).createObjectURL(content);
  element.setAttribute('href', hrefValue);
  element.setAttribute('download', fileName);
  element.style.display = 'none';
  document.body.appendChild(element);
  element.click();
  document.body.removeChild(element);
}

// ArrayBuffer から文字列への変換
// https://gist.github.com/kawanet/352a2ed1d1656816b2bc
export function buffer_to_string(buf: ArrayBuffer) {
  return String.fromCharCode.apply("", Array.from(new Uint16Array(buf)))
}

// ただし、文字列が長すぎる場合は RangeError: Maximum call stack size exceeded. が発生してしまう。
// 以下は1024バイト単位に分割して処理する場合
// https://gist.github.com/kawanet/352a2ed1d1656816b2bc
export function large_buffer_to_string(buf: ArrayBuffer) {
  const tmp = [];
  const len = 1024;
  for (let p = 0; p < buf.byteLength; p += len) {
    tmp.push(buffer_to_string(buf.slice(p, p + len)));
  }
  return tmp.join("");
}

// CognitoUser

export type UserChallenge =
  'CUSTOM_CHALLENGE' |
  'NEW_PASSWORD_REQUIRED' |
  'SMS_MFA' |
  'SOFTWARE_TOKEN_MFA' |
  'MFA_SETUP' |
  undefined;

export interface SignInResultInterface extends CognitoUser {
  challengeName: UserChallenge;
}

export default {
  getRandomNumber,
  randomDate,
  repeat,
}