import { InjectionKey, inject as VueInject, reactive } from 'vue';
import { AlertType } from '@/resources/AlertType';

export interface AlertStateInterface {
  readonly messageKey: string;
  readonly message: string;
  readonly alertType: AlertType;
}

class AlertState implements AlertStateInterface{
  messageKey = '';
  message = '';
  alertType = AlertType.NONE;
}

export interface AlertStoreInterface {
  readonly state: AlertStateInterface;
  setAlertMessageKey(messageKey: string, alertType: AlertType): void;
  setAlertMessage(message: string, alertType: AlertType): void;
}

class AlertStore implements AlertStoreInterface {
  private _state: AlertState;
  get state(): AlertStateInterface {
    return this._state;
  }
  constructor(
  ){
    this._state = reactive(new AlertState());
  }
  setAlertMessageKey(messageKey: string, alertType: AlertType): void {
    this._state.messageKey = messageKey;
    this._state.alertType = alertType;
    this.clearDataAfterSpecifiedTime();
  }
  setAlertMessage(message: string, alertType: AlertType): void {
    this._state.message = message;
    this._state.alertType = alertType;
    this.clearDataAfterSpecifiedTime();
  }
  private clearDataAfterSpecifiedTime(milliSeconds = 5000): void {
    setTimeout(() => {
      this._state.messageKey = '';
      this._state.message = '';
      this._state.alertType = AlertType.NONE;
    }, milliSeconds);
  }
}

export class AlertStoreFactory {
  create(): AlertStoreInterface {
    return new AlertStore();
  }
}

export const alertKey: InjectionKey<AlertStoreInterface> = Symbol("AlertStoreInterface");

// export const provideAlert = () => {
//   provide(alertKey, new AlertStoreFactory().create());
// }
export const useAlert = () => VueInject(alertKey) as AlertStoreInterface;