import { UserGroup } from "mtrans-online-ui";
import { GlossaryAccessRight } from "./GlossaryAccessRight";
import { GlossaryDisclosureState } from "./GlossaryDisclosureState";
import GlossaryInterface from "./GlossaryInterface";

export default class implements GlossaryInterface {
  readonly fullQualifiedName: string = "";
  readonly glossaryID: string = "";
  readonly disclosureState: GlossaryDisclosureState | null = null;
  readonly creationDate: string = "";
  readonly creationTime: string = "";
  readonly lastUpdateDate: string = "";
  readonly lastUpdateTime: string = "";
  readonly createdBy: string = "";
  readonly lastUpdatedBy: string = "";  
  readonly numberOfTerms: number = 0;
  readonly accessibleUserGroups: UserGroup[] = [];
  readonly glossaryAccessRights: GlossaryAccessRight[] = [];
  get glossaryName(): string {    
    return this.fullQualifiedName.substring(0, this.fullQualifiedName.lastIndexOf('/'))
  }
  constructor(glossary: GlossaryInterface) {
    if(glossary.fullQualifiedName) this.fullQualifiedName = glossary.fullQualifiedName;
    if(glossary.glossaryID) this.glossaryID = glossary.glossaryID;
    if(glossary.disclosureState) this.disclosureState = glossary.disclosureState;
    if(glossary.creationDate) this.creationDate = glossary.creationDate;
    if(glossary.creationTime) this.creationTime = glossary.creationTime;
    if(glossary.lastUpdateDate) this.lastUpdateDate = glossary.lastUpdateDate;
    if(glossary.lastUpdateTime) this.lastUpdateTime = glossary.lastUpdateTime;
    if(glossary.createdBy) this.createdBy = glossary.createdBy;
    if(glossary.lastUpdatedBy) this.lastUpdatedBy = glossary.lastUpdatedBy;
    if(glossary.numberOfTerms) this.numberOfTerms = glossary.numberOfTerms;
    if(glossary.accessibleUserGroups) this.accessibleUserGroups = glossary.accessibleUserGroups;
    if(glossary.glossaryAccessRights) this.glossaryAccessRights = glossary.glossaryAccessRights;
  }
  
}