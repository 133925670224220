import LoadContractsInterface, { rtnType } from './LoadContractsInterface'
import { injectable } from 'tsyringe';
import { Auth, API } from 'aws-amplify';
import * as Sentry from '@sentry/browser';
import ContractInterface from '@/entities/ContractInterface';
import { createQueryParamsString, encodeToUrlSafe } from '@/entities/Utility';
import QueryResultInterface from '@/entities/QueryResultInterface';
import Contract from '@/entities/Contract';
import { HTTPRequestAsync } from '@/infra/HTTPRequest';

@injectable()
export default class implements LoadContractsInterface {
  async handleAsync(args: {
    pageSize?: number;
    paginationTokenForNext?: string;
    paginationTokenForPrevious?: string;
    backwardSearch?: boolean;
    searchKeyword?: string;
    filterInProgress?: boolean;
    filterCancel?: boolean;
    descStartDate?: boolean;
  }): Promise<rtnType> {

    try {      
      const path = `/v2/my-contracts?`
                   + createQueryParamsString({
                    "page-size": `${args.pageSize}`,
                    "pagination-token": `${args.backwardSearch ? args.paginationTokenForPrevious : args.paginationTokenForNext}`,
                    "backward-search": `${args.backwardSearch}`,
                    "search-keyword": `${args.searchKeyword}`,
                    "filter-in-progress": `${args.filterInProgress}`,
                    "filter-cancel": `${args.filterCancel}`,
                    "desc-start-date": `${args.descStartDate}`
                   });
      
      const session = await Auth.currentSession();
      // #4434      
      const result = await HTTPRequestAsync('get', {
        apiName: 'MTrans.API.Users',
        altAPIName: 'MTrans.API.Users.ALT',
        path,
        init: { headers: { "Authorization": session.getIdToken().getJwtToken() }}
      })
      // const result = await API.get('MTrans.API.Users', path, {
      //   headers: {
      //     "Authorization": session.getIdToken().getJwtToken()
      //   }
      // });
      const queryResult = result as QueryResultInterface;
  
      const rtn: rtnType = {
        contractInterfaces: [],
        paginationTokenForNext : '',
        paginationTokenForPrevious : '',
        isLast: false
      };
  
      const contracts = queryResult.items?.map<ContractInterface>((value) => new Contract({
        contractID: value.attributes['ContractID'].value as unknown as string,
        customerID: value.attributes['CustomerID'].value as unknown as string,
        startDate: value.attributes['StartDate'].value as unknown as string,
        endDate: value.attributes['EndDate'].value as unknown as string,
        mtransApplicationIDList: value.attributes['MTransApplicationIDList'].value as unknown as string[],
        mtransFeatureIDList: value.attributes['MTransFeatureIDList'].value as unknown as string[],
        mtEngineIDList: value.attributes['MTEngineIDList'].value as unknown as string[],
        numberOfUsers: value.attributes['NumberOfUsers'].value as unknown as number | null,
        contractUserListCount: value.attributes['ContractUserListCount'] ? value.attributes['ContractUserListCount'].value as unknown as number : 0,
        contractPhase: value.attributes['ContractPhase'].value as unknown as number,
        contractCancelled: value.attributes['ContractCancelled'].value as unknown as boolean,
      }));
      
      rtn.isLast = (queryResult.isLast as unknown as boolean);
  
      rtn.contractInterfaces= args.backwardSearch ? contracts.reverse() : contracts;
  
      rtn.paginationTokenForNext = (() => {
        if(!args.backwardSearch && rtn.isLast) return '';
        if(rtn.contractInterfaces.length === 0) return '';
        return this.createPaginationToken(rtn.contractInterfaces[rtn.contractInterfaces.length - 1]);
      })();
  
      rtn.paginationTokenForPrevious = (() => {
        if(args.backwardSearch && rtn.isLast) return '';
        if(!args.paginationTokenForPrevious && !args.paginationTokenForNext) return '';
        if(rtn.contractInterfaces.length === 0) return '';
        return this.createPaginationToken(rtn.contractInterfaces[0]);
      })();
    
      return rtn;
    } catch (error) {      
      Sentry.captureException(error);
      throw error;
    }
  }

  private createPaginationToken(contract: ContractInterface) {
    const encContractID = encodeToUrlSafe(contract.contractID);
    return encContractID;
  }
}