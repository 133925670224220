export enum MTransApplication  {
  MTransForOffice = 1,
  MTransForPhraseTMS = 2,
  MTransForTrados = 3,
  MTransTMFindAndReplace = 4,
  MTransForCrowdin,
  MTransAPI = "A"
}

export function getMTransApplicationName(app: MTransApplication | string): string {
  switch (`${app}`) {
    case `${MTransApplication.MTransForOffice}`:
      return "MTrans for Office";
    case `${MTransApplication.MTransForPhraseTMS}`:
      return "MTrans for Phrase TMS";
    case `${MTransApplication.MTransForTrados}`:
      return "MTrans for Trados";      
    case `${MTransApplication.MTransTMFindAndReplace}`:
      return "MTrans TM Find and Replace";      
    case `${MTransApplication.MTransForCrowdin}`:
      return "MTrans for Crowdin";      
    case `${MTransApplication.MTransAPI}`:
      return "MTrans API";      
    default:
      throw new Error();
  }
}
