// Reference:
// Manage Vue i18n with Typescript: https://medium.com/js-dojo/manage-vue-i18n-with-typescript-958b2f69846f
// intlify/vue-i18n-next: https://github.com/intlify/vue-i18n-next

import { Locales } from "@/resources/locales";
import {createI18n} from 'vue-i18n'
import getMessages from '@/translation';

export const messages = getMessages();

export const defaultLocale = Locales.EN;

export const GetLocale = (langauge: string) => {
    if (langauge?.toLowerCase() === 'ja') {
        return Locales.JA
    } else {
        return Locales.EN
    }
}

export default createI18n({
    legacy: false,    
    globalInjection: true,    
    messages,    
    locale: defaultLocale,
    fallbackLocale: defaultLocale,
});