// Re-use existing authentication resource: https://docs.amplify.aws/lib/auth/start/q/platform/js#re-use-existing-authentication-resource

const awsExports = {
  Auth: {

      identityPoolId: process.env.VUE_APP_AWS_AUTH_IDENTITY_POOL_ID,

      // REQUIRED - Amazon Cognito Region
      region: process.env.VUE_APP_AWS_AUTH_REGION,

      // OPTIONAL - Amazon Cognito User Pool ID
      userPoolId: process.env.VUE_APP_AWS_AUTH_USER_POOL_ID,

      // OPTIONAL - Amazon Cognito Web Client ID (26-char alphanumeric string)
      userPoolWebClientId: process.env.VUE_APP_AWS_AUTH_USER_POOL_WEB_CLIENT_ID,

      // OPTIONAL - Enforce user authentication prior to accessing AWS resources or not
      mandatorySignIn: false,

      // OPTIONAL - Manually set the authentication flow type. Default is 'USER_SRP_AUTH'
      authenticationFlowType: 'USER_PASSWORD_AUTH',

      // OPTIONAL - Manually set key value pairs that can be passed to Cognito Lambda Triggers
      clientMetadata: { myCustomKey: 'myCustomValue' },

      oauth: {
        domain: `${process.env.VUE_APP_AWS_AUTH_DOMAIN_PREFIX}.auth.${process.env.VUE_APP_AWS_AUTH_REGION}.amazoncognito.com`,
        scope: ['openid'],
        redirectSignIn: process.env.VUE_APP_AWS_AUTH_OAUTH_RedirectSignIn,
        redirectSignOut: process.env.VUE_APP_AWS_AUTH_OAUTH_RedirectSignOut,
        responseType: 'code',
      },
      
  },
  API: {
    endpoints: [
      {
        name: "MTrans.API.Users",
        endpoint: process.env.VUE_APP_MTRANS_API_USERS
      },
      {
        name: "MTrans.API.Users.ALT",
        endpoint: process.env.VUE_APP_MTRANS_API_USERS_ALT
      },
      {
        name: "MTrans.Account.UserGroup.Command.API",
        endpoint: process.env.VUE_APP_MTRANS_ACCOUNT_USERGROUP_COMMAND_API
      },
      {
        name: "MTrans.Account.UserGroup.Command.API.ALT",
        endpoint: process.env.VUE_APP_MTRANS_ACCOUNT_USERGROUP_COMMAND_API_ALT
      },
      {
        name: "MTrans.Account.UserGroup.Query.API",
        endpoint: process.env.VUE_APP_MTRANS_ACCOUNT_USERGROUP_QUERY_API
      },
      {
        name: "MTrans.Account.UserGroup.Query.API.ALT",
        endpoint: process.env.VUE_APP_MTRANS_ACCOUNT_USERGROUP_QUERY_API_ALT
      },
      {
        name: "MTrans.Auth.SourceIPAddress.Query.API",
        endpoint: process.env.VUE_APP_MTRANS_AUTH_SOURCE_IP_ADDRESS_QUERY_API
      },
      {
        name: "MTrans.Auth.SourceIPAddress.Query.API.ALT",
        endpoint: process.env.VUE_APP_MTRANS_AUTH_SOURCE_IP_ADDRESS_QUERY_API_ALT
      },
      {
        name: "MTrans.Glossary.Query.API",
        endpoint: process.env.VUE_APP_MTRANS_GLOSSARY_QUERY_API
      },
      {
        name: "MTrans.Glossary.Query.API.ALT",
        endpoint: process.env.VUE_APP_MTRANS_GLOSSARY_QUERY_API_ALT
      },
      {
        name: "MTrans.Glossary.API",
        endpoint: process.env.VUE_APP_MTRANS_GLOSSARY_API
      },
      {
        name: "MTrans.Glossary.API.ALT",
        endpoint: process.env.VUE_APP_MTRANS_GLOSSARY_API_ALT
      },
      // {
      //   name: "MTrans.MT.Usage.Query.API",
      //   endpoint: process.env.VUE_APP_MTRANS_MT_USAGE_QUERY_API
      // },
      // {
      //   name: "MTrans.MT.Usage.Query.API.ALT",
      //   endpoint: process.env.VUE_APP_MTRANS_MT_USAGE_QUERY_API_ALT
      // },
      {
        name: "MTrans.Account.APIKey.Command.API",
        endpoint: process.env.VUE_APP_MTRANS_ACCOUNT_APIKEY_COMMAND_API
      },
      {
        name: "MTrans.Account.APIKey.Command.API.ALT",
        endpoint: process.env.VUE_APP_MTRANS_ACCOUNT_APIKEY_COMMAND_API_ALT
      },
      {
        name: "MTrans.User.Usage.Query.API",
        endpoint: process.env.VUE_APP_MTRANS_USER_USAGE_QUERY_API
      },
      {
        name: "MTrans.User.Usage.Query.API.ALT",
        endpoint: process.env.VUE_APP_MTRANS_USER_USAGE_QUERY_API_ALT
      },
    ]
  },
  Storage: {
    AWSS3: {
        bucket: process.env.VUE_APP_MTRANS_GLOSSARY_IMPORT_BUCKET,
        region: process.env.VUE_APP_AWS_AUTH_REGION
    }    
  }
};

export default awsExports;